// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/alarm';

async function get(limit, offset) {
	let params = {
		limit: limit,
		offset: offset,
	};
	return axiosApi.get(`${urlPath}`, { params });
}
async function count() {
	let res = await axiosApi.get(`${urlPath}/count`);
	let count = res.total;
	return count;
}

async function removeAll() {
	await axiosApi.delete(`${urlPath}/deleteAll`);
}

async function remove(id) {
	await axiosApi.delete(`${urlPath}/${id}`);
}

export default { get, count, removeAll, remove };
