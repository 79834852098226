<template>
	<div id="wrap">
		<Header />
		<div id="container" @scroll="scrollEndPoint">
			<router-view />
			<Footer />
		</div>
	</div>
</template>

<script>
import Header from './Header';
import Footer from './Footer';

export default {
	components: {
		Header,
		Footer,
	},
	data() {
		return {
			isAtTheBottom: false,
		};
	},
	mounted() {
		window.addEventListener('scroll', this.scrollEndPoint);
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollEndPoint);
	},
	methods: {
		scrollEndPoint(e) {
			let scroll = document.documentElement;
			if (e.target != document) {
				scroll = document.querySelector('#container');
			}
			const { scrollHeight, scrollTop, clientHeight } = scroll;
			if ((scrollHeight - clientHeight) * 0.8 <= scrollTop) {
				if (!this.isAtTheBottom) {
					this.$EventBus.$emit('paging');
					this.isAtTheBottom = true;
				}
			} else if (this.isAtTheBottom) {
				this.isAtTheBottom = false;
			}
		},
	},
};
</script>

<style></style>
