// import axios from 'axios';
import axiosApi from '@/api/index';
import { setAuth } from '@/utils/cmm';
import Cookies from 'js-cookie';

//let axiosApiRaw = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

// axiosApiRaw.defaults.headers.commonRlo['Authorization'] = process.env.VUE_APP_AUTH;

let urlPath = '/api';

const config = {
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
	},
};

async function login(id, password) {
	if (!id || !password) {
		throw new Error('invalid parameter');
	}

	const params = new URLSearchParams();
	params.append('loginId', id);
	params.append('password', password);

	let res = await axiosApi.post(`${urlPath}/login`, params, config);

	const resString = JSON.stringify(res);

	// Cookies.set('authToken', resString, { expires: 7, domain: '.insitenote.com', SameSite: 'None' });

	Cookies.set('authToken', resString, { expires: 7, domain: '.insitenote.com' });
	Cookies.set('accountingStatus', 'OPEN', { expires: 7, domain: '.insitenote.com' });
	Cookies.set('buildingId', res.id, { expires: 7, domain: '.insitenote.com' });
	Cookies.set('INSITE_JSESSIONID', res.session, { expires: 7, domain: '.insitenote.com' });

	Cookies.set('authToken', resString, { expires: 7, domain: 'localhost' });
	Cookies.set('accountingStatus', 'OPEN', { expires: 7, domain: 'localhost' });
	Cookies.set('buildingId', res.id, { expires: 7, domain: 'localhost' });
	Cookies.set('INSITE_JSESSIONID', res.session, { expires: 7, domain: 'localhost' });

	// Cookies.set('INSITE_JSESSIONID', res.session, { expires: 7 });
	// Cookies.set('authToken', resString, { expires: 7 });
	// Cookies.set('accountingStatus', JSON.stringify('OPEN'), { expires: 7 });
	// Cookies.set('buildingId', res.id, { expires: 7 });

	//setAuth(Object.assign({ loginId: req.userId }, resData.data));
	setAuth(res);
	//test 
	return res;
}

async function logout() {
	let params = {};
	await axiosApi.get(`${urlPath}/logout`, { params });
	setAuth(null);

	Cookies.remove('accountingStatus');
	Cookies.remove('authToken');
	Cookies.remove('buildingId');
	Cookies.remove('INSITE_JSESSIONID');
	Cookies.remove('INSITE_JSESSIONID', { path: '/', domain: '.insitenote.com', SameSite: 'None' });
	Cookies.remove('buildingId', { path: '/', domain: '.insitenote.com', SameSite: 'None' });
	Cookies.remove('accountingStatus', { path: '/', domain: '.insitenote.com', SameSite: 'None' });
	// Cookies.set('INSITE_JSESSIONID', '', { path: '/', domain: '.insitenote.com', SameSite: 'None' });
	// Cookies.set('INSITE_JSESSIONID', 'NONE', { path: '/', SameSite: 'None' });
}

export default { login, logout };
