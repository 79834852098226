<template>
	<!-- footer -->
	<div id="footer">
		<!-- inner -->
		<div class="inner">
			<p class="logo">INSITENOTE</p>
			<!-- left -->
			<div class="left">
				<ul>
					<li><strong>고객센터</strong><a href="tel:070-8709-7314">070-8709-7314</a></li>
					<li>
						<strong>평일</strong>
						<p>am 09:00 ~ pm 17:00</p>
					</li>
				</ul>
				<address>
					<span>주식회사 키박스</span><span>대표이사 김재환</span><span>사업자등록번호 211-86-96401</span><br /><span
						>서울특별시 강남구 논현로79길 73, 3층(역삼동, 수연빌딩)</span
					>
				</address>
			</div>
			<!--// left -->
			<!-- right -->
			<div class="right">
				<ul class="util">
					<!-- <li><a @click="companyIntroduction">회사소개</a></li> -->
					<li><a @click="term1">이용약관</a></li>
					<li>
						<a @click="term2"><strong>개인정보처리방침</strong></a>
					</li>
				</ul>
				<p class="copy">Copyright 2021 KEYBOX All rights reserved.</p>
			</div>
			<!--// right -->
		</div>
		<!--// inner -->
	</div>
	<!--// footer -->
</template>

<script>
export default {
	methods: {
		companyIntroduction() {
			if (this.$route.path === '/user/companyIntroduction') return;
			this.$router.push({ name: 'companyIntroduction' });
		},
		term1() {
			if (this.$route.path === '/user/term1') return;
			this.$router.push({ name: 'term1' });
		},
		term2() {
			if (this.$route.path === '/user/term2') return;
			this.$router.push({ name: 'term2' });
		},
	},
};
</script>

<style scoped></style>
