<template>
	<div id="header">
		<!-- inner -->
		<div class="inner">
			<h1><a href="/building">INSITENOTE</a></h1>
			<div class="tab-container">
				<a href="/" class="active">시설 관리</a>
				<a :href="'https://ams.insitenote.com/accounting'" style="border-left: 1px solid #999; width:0.08rem;"
					>회계 관리</a
				>
			</div>
			<a class="btnNotice" name="layerAlarm" @click="onAlarmList()" v-if="isAuthorized"
				>알림<span v-if="alarmCount > 0">{{ alarmCount }}</span></a
			>
		</div>
		<!--// inner -->
		<!-- gnb -->
		<div id="gnb" :class="{ mNone: !$store.state.menu }" v-if="isAuthorized">
			<div class="inner">
				<ul>
					<li :class="[{ curr: '/building' === parentPath }, { curr: '/tenant' === parentPath }]">
						<router-link :to="{ name: 'buildingList' }">내건물</router-link>
					</li>
					<li :class="{ curr: '/community' === parentPath }">
						<router-link :to="{ name: 'communityList' }">커뮤니티</router-link>
					</li>
					<li :class="{ curr: '/user' === parentPath }">
						<router-link :to="{ name: 'user' }">내정보</router-link>
					</li>
					<li>
						<a href="javascript: void(0)" @click="onLogout">로그아웃</a>
					</li>
				</ul>
				<strong>
					{{ nickname }}(<span>{{ loginId }}</span
					>)
				</strong>
			</div>
		</div>
		<!--// gnb -->
		<!-- 알람 팝업 -->
		<div :class="alarmLayer" id="layerAlarm">
			<div class="inner" style="width:720px">
				<strong class="tit">알림</strong>
				<div class="cont">
					<!-- 2022-05-14 -->
					<!-- listTop -->
					<div class="listTop v2">
						<!-- right -->
						<div class="right">
							<button
								type="button"
								class="btn btnWhite ss"
								@click="deleteAlarm()"
								:disabled="alarmCount == 0 ? true : false"
							>
								모든 알람 삭제
							</button>
						</div>
						<!--// right -->
					</div>
					<!--// listTop -->
					<!--// 2022-05-14 -->
					<!-- noticeList -->
					<ul class="noticeList" v-if="alarmCount > 0">
						<li v-for="alarm in alarmList" v-bind:key="alarm.id">
							<a @click="onMemo(alarm.id, alarm.memoId)">
								<span>{{ alarm.createDate | dateFormatYear }} {{ alarm.createDate | dateFormatMonth }}</span>
								<p>
									{{ alarm.content }}
								</p>
							</a>
						</li>
					</ul>
					<ul class="noticeList" v-else>
						<div class="noData">알림이 존재하지 않습니다.</div>
					</ul>
					<!--// noticeList -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn fn" name="layerAlarm" @click="closeAlarm">닫기</button>
					</div>
					<!--// btnArea -->
				</div>
				<a name="layerAlarm" @click="closeAlarm">닫기</a>
			</div>
		</div>
		<!-- 알람 팝업 -->
		<!-- 친구추가 안내 -->
		<div :class="friendsLayer" id="layerAddFriends" v-if="friendsTotal > 0">
			<div class="inner" style="width:320px">
				<div class="cont">
					<div class="inBox">
						<strong>공유회원 안내</strong>
						<p>
							'{{ friendNickname }}'님께서 '{{ friendBuildingName }}'으로 <br />
							건물공유를 요청하셨습니다. <br />수락하시겠습니까?
						</p>
					</div>
				</div>
				<div class="btnArea">
					<button type="button" class="btn btnGray" name="layerAddFriends" @click="onRejectFriend(ownerId)">
						취소
					</button>
					<button type="button" class="btn" name="layerAddFriends" @click="onAcceptFriend(ownerId)">
						확인
					</button>
				</div>
			</div>
		</div>
		<!-- 친구추가 안내 -->
	</div>
	<!--// header -->
</template>

<script>
import auth from '@/api/auth';
import alarm from '@/api/alarm';
import friend from '@/api/friend';
import Cookies from 'js-cookie';

import { showSpinner, hideSpinner, getAuth, dateFormatYear, dateFormatMonth, isAuthorized } from '@/utils/cmm';

export default {
	mounted() {
		if (isAuthorized()) {
			this.onAlarmCount();
			this.onAlarmCountStart();
			this.onGetAddFriend();
		}
	},
	filters: {
		dateFormatYear,
		dateFormatMonth,
	},
	data() {
		return {
			pathName: this.$route.path,
			alarmCount: 0,
			alarmList: [],
			alarmInterval: null,
			alarmLayer: 'layerPop',
			friends: [],
			friendsTotal: 0,
			friendsLayer: 'layerPop alert',
			friendNickname: '',
			friendIndex: 0,
			ownerId: '',
			friendBuildingId: '',
			isAuthorized: isAuthorized(),
		};
	},
	computed: {
		parentPath() {
			let pos = this.$route.path.indexOf('/', 1);
			return ~pos ? this.$route.path.substr(0, pos) : this.$route.path;
		},
		loginId() {
			let auth = getAuth();
			return auth ? auth.loginId : '-';
		},
		nickname() {
			let auth = getAuth();
			return auth ? auth.nickname : 'GUEST';
		},
	},
	methods: {
		async onLogout() {
			try {
				// Clear cookies

				Object.keys(Cookies.get()).forEach(function(cookieName) {
					Cookies.remove(cookieName, { domain: 'ams.insitenote.com', path: '/' });
				});

				Object.keys(Cookies.get()).forEach(function(cookieName) {
					Cookies.remove(cookieName, { domain: '.insitenote.com', path: '/' });
				});

				await auth.logout();
				await this.onAlarmCountStop();
				// eslint-disable-next-line no-empty
			} catch (ex) {}

			await this.$alert('로그아웃되었습니다.\r\n로그인 페이지로 이동합니다.');
			this.$router.push({ name: 'login' });
		},
		async onAlarmCount() {
			try {
				this.alarmCount = await alarm.count();
			} catch (ex) {
				return await this.$alert(`${ex.message}`, '알림 안내');
			}
		},
		async onAlarmCountStart() {
			this.alarmInterval = setInterval(async () => {
				try {
					if (this.alarmLayer == 'layerPop on') {
						// 1. 알림 레이어(OPEN), 알림리스트와 알림 숫자 모두 갱신
						let res = await alarm.get();
						this.alarmCount = res.total;
						this.alarmList = res.data;
					} else {
						// 2. 알림 레이어(CLOSE), 알림 숫자만 갱신
						this.alarmCount = await alarm.count();
					}
				} catch (ex) {
					if (ex.status == 401) {
						this.onAlarmCountStop();
						return;
					}
				}
			}, 60000);
		},
		async onAlarmCountStop() {
			clearInterval(this.alarmInterval);
		},
		async onAlarmList() {
			// 모바일 스크롤 영역 - 바깥쪽 스크롤 영역 비활성화
			document.documentElement.style.overflow = 'hidden';
			try {
				showSpinner();
				let res = await alarm.get();
				this.alarmCount = res.total;
				this.alarmList = res.data;
				this.alarmLayer = 'layerPop on';
			} catch (ex) {
				return await this.$alert(`${ex.message}`, '알림 안내');
			} finally {
				hideSpinner();
			}
		},
		closeAlarm() {
			this.alarmLayer = 'layerPop';
			// 모바일 스크롤 영역 - 바깥쪽 스크롤 영역 활성화
			document.documentElement.style.overflow = 'auto';
		},
		async deleteAlarm() {
			if (!(await this.$confirm('알림을 전부 삭제하시겠습니까?', '알림 안내'))) return;
			try {
				showSpinner();
				await alarm.removeAll();
				let res = await alarm.get();
				this.alarmCount = res.total;
				this.alarmList = res.data;
			} catch (ex) {
				return await this.$alert(`${ex.message}`, '알림 안내');
			} finally {
				hideSpinner();
			}
			// 모바일 스크롤 영역 - 바깥쪽 스크롤 영역 활성화
			document.documentElement.style.overflow = 'auto';
		},
		async onGetAddFriend() {
			if (this.$route.path == '/building') {
				try {
					let res = await friend.friendReq();
					if (res.total > 0) {
						this.friends = res.data;
						this.friendsTotal = res.total;
						this.nextFriend();
						this.friendsLayer = 'layerPop alert on';
					}
				} catch (ex) {
					return await this.$alert(`${ex.message}`, '공유회원 안내');
				}
			}
		},
		async onAcceptFriend() {
			try {
				let auth = getAuth();
				await friend.acceptFriend(this.ownerId, auth.id, this.friendBuildingId);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}

			if (this.friendsTotal > this.friendIndex) {
				this.nextFriend();
			} else {
				this.$EventBus.$emit('reload');
				this.friendsLayer = 'layerPop alert';
			}
		},
		async onRejectFriend() {
			try {
				let auth = getAuth();
				await friend.remove(this.ownerId, auth.id, this.friendBuildingId);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}

			if (this.friendsTotal > this.friendIndex) {
				this.nextFriend();
			} else {
				this.$EventBus.$emit('reload');
				this.friendsLayer = 'layerPop alert';
			}
		},
		async onMemo(id, memoId) {
			try {
				await alarm.remove(id);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
			this.alarmCount = await alarm.count();
			this.closeAlarm();
			const path = `/building/memo/${memoId}`;
			if (this.$route.path !== path) this.$router.push(path);
		},
		nextFriend() {
			this.ownerId = this.friends[this.friendIndex].userId;
			this.friendNickname = this.friends[this.friendIndex].userNickname;
			this.friendBuildingId = this.friends[this.friendIndex].buildingId;
			this.friendBuildingName = this.friends[this.friendIndex].buildingName;
			this.friendIndex += 1;
		},
		onNavigate(key) {
			this.currentTab = key;
			this.$router.push(key);
		},
	},
};
</script>
<style scoped>
.tab-container a {
	margin-inline: 10px;
	padding: 0 10px;
	border: none;
	background: transparent;
	cursor: pointer;
	font-size: 18px;
	color: #999999;
}
.tab-container a.active {
	color: #007bff;
	/* border-bottom: 2px solid #007BFF; */
}
</style>
